<template>
  <b-container fluid class="reports-performance-result-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'

export default {
  name: 'PerformanceAggregatedChart',
  components: { BarChart },
  mixins: [chartOptions],
  props: ['data', 'currencySymbol', 'periodColor', 'options'],
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance_report.'
    }
  },
  methods: {
    fillChart () {
      if (!this.data.length) {
        return false
      }

      const labels = []
      const dataPeriod = []
      const dataBudget = []
      const dataPrevious = []
      const aDatasets = []

      const tooltipLabels = [
        this.$t(this.translationPath + 'chart.period'),
        this.$t(this.translationPath + 'chart.budget'),
        this.$t(this.translationPath + 'chart.prev_period')
      ]

      const aSortedData = [...this.data]
      aSortedData.sort((a, b) => a.currentRaw < b.currentRaw ? 1 : -1)
      aSortedData.sort(function (a, b) {
        if (a.currentRaw >= b.currentRaw) {
          return -1
        }
        return 1
      })

      for (let i = 0; i < aSortedData.length; i++) {
        if (aSortedData[i].belongsTo === 'group-heading' || aSortedData[i].belongsTo === 'result-data') {
          continue
        }

        if (aSortedData[i].currentRaw !== 0 || aSortedData[i].budgetRaw !== 0 || aSortedData[i].previousRaw !== 0) {
          labels.push(aSortedData[i].title)

          if (this.options.current) {
            dataPeriod.push(aSortedData[i].currentRaw)
          }
          if (this.options.budget) {
            dataBudget.push(aSortedData[i].budgetRaw)
          }
          if (this.options.previous) {
            dataPrevious.push(aSortedData[i].previousRaw)
          }
        }
      }

      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        const value = Math.round(tooltipItem.value)
        let subLabel = ''

        switch (tooltipItem.datasetIndex) {
          case 0:
            subLabel = tooltipLabels[0]
            break
          case 1:
            subLabel = tooltipLabels[1]
            break
          case 2:
            subLabel = tooltipLabels[2]
            break
        }

        return subLabel + ': ' + Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      if (this.options.current) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'period'),
          data: dataPeriod,
          type: 'bar',
          backgroundColor: this.periodColor
        })
      }

      if (this.options.budget) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'budget'),
          data: dataBudget,
          type: 'bar',
          backgroundColor: '#cbc6b5'
        })
      }

      if (this.options.previous) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'period_prev'),
          data: dataPrevious,
          type: 'bar',
          backgroundColor: '#9a9bae'
        })
      }

      this.chartOptions.legend.display = aDatasets.length > 1

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
