<template>
  <b-container fluid class="reports-performance-result-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import chartPalettes from '@/mixins/defaultChartColorPalettes'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'PerformanceAggregatedChart',
  components: { PieChart },
  mixins: [chartOptions, chartPalettes],
  props: ['data', 'currencySymbol', 'periodColor', 'options'],
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance_report.'
    }
  },
  methods: {
    fillChart () {
      if (!this.data.length) {
        return false
      }

      const labels = []
      const dataPeriod = []
      const aColors = this.getAssetGraphPalette(8)
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        return chartData.labels[tooltipItem.index] + ': ' + formatNumberToFull(chartData.datasets[0].data[tooltipItem.index]).split(' ').join('') + ' ' + currencySymbol
      }

      /* Prepare data */
      const aSortedData = this.data.filter((item) => item.belongsTo === 'group-data' ? item : null)
      aSortedData.sort((a, b) => a.currentRaw >= b.currentRaw ? -1 : 1)

      for (let i = 0; i < aSortedData.length; i++) {
        if (aSortedData[i].currentRaw !== 0) {
          labels.push(aSortedData[i].title)
          dataPeriod.push(aSortedData[i].currentRaw)
        }
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          label: this.$t(this.translationPath + 'period'),
          data: dataPeriod,
          backgroundColor: aColors
        }]
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
