<template>
  <b-container fluid class="reports-performance-result-stacked-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'

export default {
  name: 'PerformanceResultChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes],
  props: ['data', 'currencySymbol', 'options', 'apiData', 'typeOfChart'],
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance_report.'
    }
  },
  methods: {
    fillChart () {
      if (!this.data.length) {
        return false
      }

      // const aIncomeData = { label: 'incomes', data: [], backgroundColor: '', stack: 'stack0' }
      const aDatasets = []
      const tooltipLabels = []
      const aOffsetCounter = { current: 0, budget: 0, previous: 0 }
      let iOffsetCounter = 0
      let aSortedIncomeData = []
      let aSortedExpenseData = []
      const labels = [
        this.$t('common.incomes'),
        this.$t('common.expenses'),
        this.$t('common.result')
      ]
      this.chartOptions.legend.display = false
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }
      this.chartOptions.scales.xAxes[0].stacked = true

      /* Prepare data */
      if (this.typeOfChart === 'aggregated') {
        aSortedIncomeData = this.data.filter(function (item) {
          return item.belongsTo === 'group-data' && item.account_type === 'income' ? item : null
        })
        aSortedExpenseData = this.data.filter(function (item) {
          return item.belongsTo === 'group-data' && item.account_type === 'expense' ? item : null
        })
      } else if (this.typeOfChart === 'detailed') {
        aSortedIncomeData = this.data.filter(function (item) {
          return item.belongsTo === 'account-data' && item.account_type === 'income' ? item : null
        })
        aSortedExpenseData = this.data.filter(function (item) {
          return item.belongsTo === 'account-data' && item.account_type === 'expense' ? item : null
        })
      }

      aSortedIncomeData.sort((a, b) => a.currentRaw >= b.currentRaw ? -1 : 1)
      aSortedExpenseData.sort((a, b) => a.currentRaw >= b.currentRaw ? -1 : 1)

      // Current amounts
      if (this.options.current) {
        for (const idx in aSortedIncomeData) {
          aDatasets.push({
            label: aSortedIncomeData[idx].title, data: [aSortedIncomeData[idx].currentRaw, 0, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: [this.getGreen(), '', ''], stack: 'currentAmountStack'
          })
          tooltipLabels.push(aSortedIncomeData[idx].title)
        }
        for (const idx in aSortedExpenseData) {
          aDatasets.push({
            label: aSortedExpenseData[idx].title, data: [0, aSortedExpenseData[idx].currentRaw, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: ['', this.getRed(), ''], stack: 'currentAmountStack'
          })
          tooltipLabels.push(aSortedExpenseData[idx].title)
        }
        aDatasets.push({ label: '', data: [0, 0, this.apiData.totals.result.current_period], backgroundColor: this.getPurple(), stack: 'currentAmountStack' })
        tooltipLabels.push(this.$t(this.translationPath + 'actual_amount').toString())
        iOffsetCounter = aSortedIncomeData.length + aSortedExpenseData.length + 1
      }

      // Budget
      if (this.options.budget) {
        aOffsetCounter.budget = iOffsetCounter
        for (const idx in aSortedIncomeData) {
          aDatasets.push({ label: aSortedIncomeData[idx].title, data: [aSortedIncomeData[idx].budgetRaw, 0, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: ['rgba(203,198,181,0.8)', '', ''], stack: 'budgetStack' })
          tooltipLabels.push(aSortedIncomeData[idx].title + ' (' + this.$t('common.budget').toString().toLowerCase() + ')')
        }
        for (const idx in aSortedExpenseData) {
          aDatasets.push({ label: aSortedExpenseData[idx].title, data: [0, aSortedExpenseData[idx].budgetRaw, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: ['', 'rgba(203,198,181,0.8)', ''], stack: 'budgetStack' })
          tooltipLabels.push(aSortedExpenseData[idx].title + ' (' + this.$t('common.budget').toString().toLowerCase() + ')')
        }
        aDatasets.push({ label: '', data: [0, 0, this.apiData.totals.result.budget], backgroundColor: 'rgba(203,198,181,0.8)', stack: 'budgetStack' })
        tooltipLabels.push(this.$t('common.budget') + ' ')
        iOffsetCounter = aSortedIncomeData.length + aSortedExpenseData.length + 1 + iOffsetCounter
      }

      // If show previous period
      if (this.options.previous) {
        aOffsetCounter.previous = iOffsetCounter
        for (const idx in aSortedIncomeData) {
          aDatasets.push({ label: aSortedIncomeData[idx].title, data: [aSortedIncomeData[idx].previousRaw, 0, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: ['rgba(154,155,174,0.8)', '', ''], stack: 'previousAmountStack' })
          tooltipLabels.push(aSortedIncomeData[idx].title + ' (' + this.$t(this.translationPath + 'previous_period').toString().toLowerCase() + ')')
        }
        for (const idx in aSortedExpenseData) {
          aDatasets.push({ label: aSortedExpenseData[idx].title, data: [0, aSortedExpenseData[idx].previousRaw, 0], borderColor: 'white', borderWidth: { top: 2 }, backgroundColor: ['', 'rgba(154,155,174,0.8)', ''], stack: 'previousAmountStack' })
          tooltipLabels.push(aSortedExpenseData[idx].title + ' (' + this.$t(this.translationPath + 'previous_period').toString().toLowerCase() + ')')
        }
        aDatasets.push({ label: '', data: [0, 0, this.apiData.totals.result.previous_period], backgroundColor: 'rgba(154,155,174,0.8)', stack: 'previousAmountStack' })
        tooltipLabels.push(this.$t(this.translationPath + 'previous_period').toString())
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }

      const self = this
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        const subLabel = tooltipLabels[tooltipItem.datasetIndex] // aDatasets[tooltipItem.datasetIndex + aOffsetCounter.budget].data[0]
        const aOutput = [subLabel + ': ' + Math.floor(Math.round(tooltipItem.value)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol]

        if (self.options.current) {
          // const current = aDatasets[idx].data[0]
          // aOutput.push('Utfall: ' + Math.floor(Math.round(current)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol)
        }

        if (self.options.budget) {
          // const budget = aDatasets[idx + aOffsetCounter.budget].data[0]
          // aOutput.push('Budget: ' + Math.floor(Math.round(budget)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol)
        }

        if (self.options.previous) {
          // const previous = aDatasets[idx + aOffsetCounter.previous].data[1]
          // aOutput.push('Föregående: ' + Math.floor(Math.round(previous)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol)
        }

        return aOutput
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
