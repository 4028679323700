<template>
  <b-container fluid class="component-performance-report-table px-0">

    <b-row no-gutters>
      <b-col>
        <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
          <template v-slot:header>
            <b-row>
              <b-col cols="6">
                <h2>{{ title }}</h2>
              </b-col>
              <b-col cols="6">
                <b-dropdown right
                      :text="$t(translationPath + 'toggle_chart') + ' | '+ $t(translationPath + 'chart.'+ getActiveChart )"
                      variant='outline-secondary'
                      class="float-right text-regular toggleColumnWrapper"
                      size="sm"
                      v-if="!busyState"
                >
                  <b-dropdown-item v-if="activeTab==='income'" @click="toggleIncomeChart('income-detailed-half-pie-chart')" v-bind:class="{ active: activeIncomeChart === 'income-detailed-half-pie-chart'}">{{$t(translationPath + 'chart.income-detailed-half-pie-chart')}}</b-dropdown-item>
                  <!--<b-dropdown-item v-if="activeTab==='income'" @click="toggleIncomeChart('income-detailed-pie-chart')" v-bind:class="{ active: activeIncomeChart === 'income-detailed-pie-chart'}">{{$t(translationPath + 'chart.income-detailed-pie-chart')}}</b-dropdown-item>-->
                  <b-dropdown-item v-if="activeTab==='income'" @click="toggleIncomeChart('income-detailed-bar-chart')" v-bind:class="{ active: activeIncomeChart === 'income-detailed-bar-chart'}">{{$t(translationPath + 'chart.income-detailed-bar-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='income'" @click="toggleIncomeChart('income-aggregated-pie-chart')" v-bind:class="{ active: activeIncomeChart === 'income-aggregated-pie-chart'}">{{$t(translationPath + 'chart.income-aggregated-pie-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='income'" @click="toggleIncomeChart('income-aggregated-bar-chart')" v-bind:class="{ active: activeIncomeChart === 'income-aggregated-bar-chart'}">{{$t(translationPath + 'chart.income-aggregated-bar-chart')}}</b-dropdown-item>

                  <b-dropdown-item v-if="activeTab==='expense'" @click="toggleExpenseChart('expense-detailed-half-pie-chart')" v-bind:class="{ active: activeExpenseChart === 'expense-detailed-half-pie-chart'}">{{$t(translationPath + 'chart.expense-detailed-half-pie-chart')}}</b-dropdown-item>
                  <!--<b-dropdown-item v-if="activeTab==='expense'" @click="toggleExpenseChart('expense-detailed-pie-chart')" v-bind:class="{ active: activeExpenseChart === 'expense-detailed-pie-chart'}">{{$t(translationPath + 'chart.expense-detailed-pie-chart')}}</b-dropdown-item>-->
                  <b-dropdown-item v-if="activeTab==='expense'" @click="toggleExpenseChart('expense-detailed-bar-chart')" v-bind:class="{ active: activeExpenseChart === 'expense-detailed-bar-chart'}">{{$t(translationPath + 'chart.expense-detailed-bar-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='expense'" @click="toggleExpenseChart('expense-aggregated-pie-chart')" v-bind:class="{ active: activeExpenseChart === 'expense-aggregated-pie-chart'}">{{$t(translationPath + 'chart.expense-aggregated-pie-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='expense'" @click="toggleExpenseChart('expense-aggregated-bar-chart')" v-bind:class="{ active: activeExpenseChart === 'expense-aggregated-bar-chart'}">{{$t(translationPath + 'chart.expense-aggregated-bar-chart')}}</b-dropdown-item>

                  <b-dropdown-item v-if="activeTab==='overview'" @click="toggleOverviewChart('overview-total-bar-chart')" v-bind:class="{ active: activeOverviewChart === 'overview-total-bar-chart'}">{{$t(translationPath + 'chart.overview-total-bar-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='overview'" @click="toggleOverviewChart('overview-aggregated-bar-chart')" v-bind:class="{ active: activeOverviewChart === 'overview-aggregated-bar-chart'}">{{$t(translationPath + 'chart.overview-aggregated-bar-chart')}}</b-dropdown-item>
                  <b-dropdown-item v-if="activeTab==='overview'" @click="toggleOverviewChart('overview-detailed-bar-chart')" v-bind:class="{ active: activeOverviewChart === 'overview-detailed-bar-chart'}">{{$t(translationPath + 'chart.overview-detailed-bar-chart')}}</b-dropdown-item>

                </b-dropdown>

                <toggle-columns-dropdown :initial-state="options" @change="onToggleColumns" />
              </b-col>
            </b-row>
          </template>

          <div class="col-lg-9 pl-0" v-html="description"></div>

          <b-col cols="12" class="px-0 pb-5" :class="tableClass">

            <b-table class="spirecta-simple-table reports-performance-table mb-0" show-empty hover responsive striped
                     :items="data"
                     :fields="fieldValues"
                     :busy="busyState"
                     :tbodyTrClass="trClass"
                     :filter="filter"
                     :filter-included-fields="['title','title_with_code']"
            >
              <!-- LOADER -->
              <template v-slot:table-busy>
                <loader/>
              </template>

              <!-- FILTER -->
              <template slot="top-row" v-if="tableClass === 'table-detailed'">
                <td :colspan="fieldValues.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- HEAD -->
              <template v-slot:head(current)>
                {{ $t(translationPath + 'period') }}
                <br/>
                {{ currentPeriodLabel }}
              </template>
              <template v-slot:head(budget)>
                {{ $t(translationPath + 'budget') }}
                <br/>
                {{ currentPeriodLabel }}
              </template>
              <template v-slot:head(previous)>
                {{ $t(translationPath + 'period_prev') }}
                <br/>
                {{ previousPeriodLabel }}
              </template>

              <!-- TITLE -->
              <template v-slot:cell(title)="row">
                <template v-if="['type-data', 'result-data', 'subtotal-data'].indexOf(row.item.belongsTo) !== -1">
                  {{ $t(translationPath + row.item.title) }}
                </template>
                <template v-else-if="row.item.belongsTo === 'empty-row'"></template>
                <template v-else-if="row.item.belongsTo === 'group-data'">
                  <b-link @click.prevent="$emit('request-tab', row.item.account_type)">
                    {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                  </b-link>
                  <!--<b-link :to="'/reports/performance/account-groups/' + row.item.id">
                    {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                  </b-link>-->
                </template>
                <template v-else-if="row.item.belongsTo === 'group-heading'">
                    <template v-if="row.item.title!==''">
                      <b-link :to="'/reports/performance/account-groups/' + row.item.id">
                        {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                      </b-link>
                    </template>
                    <template v-else-if="activeTab === 'income-aggregated'">
                      {{ $t('common.summary_income_category_groups')}}
                    </template>
                    <template v-else-if="activeTab === 'expense-aggregated'">
                      {{ $t('common.summary_expense_category_groups')}}
                    </template>
                </template>
                <template v-else-if="row.item.belongsTo === 'account-data'">
                  <b-link :to="'/reports/performance/accounts/' + row.item.id + '/view?account_type='+row.item.account_type+'&start_date='+period.formatted.current_start_date+'&end_date='+period.formatted.current_end_date">
                    {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                  </b-link>
                </template>
                <template v-else>
                  {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                </template>
              </template>

              <!-- CURRENT AMOUNT -->
              <template v-slot:cell(current)="row">
                <template v-if="row.item.belongsTo === 'group-heading' || row.item.belongsTo === 'type-header' ">
                <span class="text-secondary">
                  {{ $t(translationPath + 'period') }}
                  <br/>
                  <small>{{ currentPeriodLabel }}</small>
                </span>
                </template>
                <template v-else-if="row.item.belongsTo === 'type-data'">
                  <b-link
                    :to="'/transactions/view?type=' + row.item.title + '&start_date=' + period.current.start.toISOString().slice(0, 10) + '&end_date=' + period.current.end.toISOString().slice(0, 10)">
                    {{ row.item.current }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'group-data'">
                  <b-link
                    :to="'/transactions/view?account_group_id=' + row.item.id + '&start_date=' + period.current.start.toISOString().slice(0, 10) + '&end_date=' + period.current.end.toISOString().slice(0, 10)">
                    {{ row.item.current }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'account-data'">
                  <b-link
                    :to="'/transactions/view?account_id=' + row.item.id + '&start_date=' + period.current.start.toISOString().slice(0, 10) + '&end_date=' + period.current.end.toISOString().slice(0, 10)">
                    {{ row.item.current }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'result-data'">
                  <b-link
                    :to="'/transactions/view?' + (row.item.type ? 'type=' + row.item.type + '&' : '') + 'start_date=' + period.current.start.toISOString().slice(0, 10) + '&end_date=' + period.current.end.toISOString().slice(0, 10)">
                    {{ row.item.current }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'subtotal-data'">
                  <b-link
                    :to="'/transactions/view?account_group_id=' + row.item.groupId + '&start_date=' + period.current.start.toISOString().slice(0, 10) + '&end_date=' + period.current.end.toISOString().slice(0, 10)">
                    {{ row.item.current }}
                  </b-link>
                </template>
                <template v-else>{{ row.item.current }}</template>
              </template>

              <!-- BUDGET AMOUNT -->
              <template v-slot:cell(budget)="row">
                <template v-if="row.item.belongsTo === 'group-heading' || row.item.belongsTo === 'type-header'">
                <span class="text-secondary">
                  {{ $t(translationPath + 'budget') }}
                  <br/>
                  <small>{{ currentPeriodLabel }}</small>
                </span>
                </template>
                <template v-else>{{ row.item.budget }}</template>
              </template>

              <!-- PREVIOUS AMOUNT -->
              <template v-slot:cell(previous)="row">
                <template v-if="row.item.belongsTo === 'group-heading' || row.item.belongsTo === 'type-header'">
                <span class="text-secondary">
                  {{ $t(translationPath + 'period_prev') }}
                  <br/>
                  <small>{{ previousPeriodLabel }}</small>
                </span>
                </template>
                <template v-else-if="row.item.belongsTo === 'type-data'">
                  <b-link
                    :to="'/transactions/view?type=' + row.item.title + '&start_date=' + period.previous.start.toISOString().slice(0, 10) + '&end_date=' + period.previous.end.toISOString().slice(0, 10)">
                    {{ row.item.previous }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'group-data'">
                  <b-link
                    :to="'/transactions/view?account_group_id=' + row.item.id + '&start_date=' + period.previous.start.toISOString().slice(0, 10) + '&end_date=' + period.previous.end.toISOString().slice(0, 10)">
                    {{ row.item.previous }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'account-data'">
                  <b-link
                    :to="'/transactions/view?account_id=' + row.item.id + '&start_date=' + period.previous.start.toISOString().slice(0, 10) + '&end_date=' + period.previous.end.toISOString().slice(0, 10)">
                    {{ row.item.previous }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'result-data'">
                  <b-link
                    :to="'/transactions/view?' + (row.item.type ? 'type=' + row.item.type + '&' : '') + 'start_date=' + period.previous.start.toISOString().slice(0, 10) + '&end_date=' + period.previous.end.toISOString().slice(0, 10)">
                    {{ row.item.previous }}
                  </b-link>
                </template>
                <template v-else-if="row.item.belongsTo === 'subtotal-data'">
                  <b-link
                    :to="'/transactions/view?account_group_id=' + row.item.groupId + '&start_date=' + period.previous.start.toISOString().slice(0, 10) + '&end_date=' + period.previous.end.toISOString().slice(0, 10)">
                    {{ row.item.previous }}
                  </b-link>
                </template>
                <template v-else>{{ row.item.previous }}</template>
              </template>
            </b-table>
            <b-link href="#" @click="exportTransactions()"  class="text-red">{{$t('common.export_to_csv' )}} →</b-link>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import ToggleColumnsDropdown from './ToggleColumnsDropdown'

export default {
  name: 'TableAggregated',
  components: { Loader, ToggleColumnsDropdown },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Aggregated'
    },
    description: {
      type: String,
      default: 'Your result data'
    },
    busyState: {
      type: Boolean,
      default: true
    },
    currentPeriodLabel: {
      type: String,
      default: '-'
    },
    previousPeriodLabel: {
      type: String,
      default: '-'
    },
    tableClass: {
      type: String,
      default: 'table-aggregated'
    },
    period: {
      type: Object
    },
    activeTab: {
      type: String,
      default: 'income'
    },
    activeOverviewChart: {
      type: String,
      default: 'overview-total-bar-chart'
    },
    activeIncomeChart: {
      type: String,
      default: 'income-detailed-half-pie-chart'
    },
    activeExpenseChart: {
      type: String,
      default: 'expense-detailed-half-pie-chart'
    },
    start_date: { type: String, default: '' },
    end_date: { type: String, default: '' },
    options: {
      type: Object,
      default:
        function () {
          return { current: true, previous: false, budget: false }
        }
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.performance_report.',
      filter: '',
      showChart: 'income-aggregated-bar-chart'
    }
  },
  created () {
    this.showChart = this.activeChart
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      const aFields = [{ key: 'title', label: '', class: 'row-title' }]

      if (this.options.current) {
        aFields.push({ key: 'current', label: 'Period', class: 'text-right' })
      }

      if (this.options.budget) {
        aFields.push({ key: 'budget', label: 'Budget', class: 'text-right' })
      }
      if (this.options.previous) {
        aFields.push({ key: 'previous', label: 'Previous period', class: 'text-right' })
      }

      return aFields
    },
    getActiveChart () {
      switch (this.activeTab) {
        case 'income' :
          return this.activeIncomeChart
        case 'expense' :
          return this.activeExpenseChart
        case 'overview' :
          return this.activeOverviewChart
      }
      return ''
    }
  },
  methods: {
    toggleIncomeChart (chartToDisplay) {
      this.showChart = chartToDisplay
      this.$emit('chart-to-display-income', chartToDisplay)
    },
    toggleExpenseChart (chartToDisplay) {
      this.showChart = chartToDisplay
      this.$emit('chart-to-display-expense', chartToDisplay)
    },
    toggleOverviewChart (chartToDisplay) {
      this.showChart = chartToDisplay
      this.$emit('chart-to-display-overview', chartToDisplay)
    },
    onToggleColumns (state) {
      this.$emit('toggle-columns', state)
    },
    trClass (item) {
      if (item === null) {
        return ''
      }
      let itemClass = item.belongsTo
      if (['empty-row', 'group-heading'].indexOf(item.belongsTo) !== -1) {
        itemClass += ' bg-white'
      }
      return itemClass
    },
    exportTransactions () {
      let sData = this.$t(this.translationPath + 'export_transactions_csv_title') + '\n\n'
      sData += this.$t(this.translationPath + 'export_transactions_csv_current_period', { period: this.currentPeriodLabel }) + '\n'
      sData += this.$t(this.translationPath + 'export_transactions_csv_previous_period', { period: this.previousPeriodLabel }) + '\n\n'
      sData += this.$t(this.translationPath + 'export_transactions_csv_heading') + '\n'
      let oRow = null

      for (const idx in this.data) {
        oRow = this.data[idx]
        switch (oRow.belongsTo) {
          /** Result **/
          case 'type-header' :
          case 'group-heading':
            sData += '\n' + oRow.title + ';\n'
            break
          case 'account-data':
          case 'group-data':
            sData += oRow.title + ';' + parseInt(oRow.currentRaw) + ';' + parseInt(oRow.budgetRaw) + ';' + parseInt(oRow.previousRaw) + ';' + oRow.account_type + ';\n'
            break
          case 'result-data':
            // sData += '\n' + this.$t('common.result') + ';' + parseInt(oRow.currentRaw) + ';' + parseInt(oRow.budgetRaw) + ';' + parseInt(oRow.previousRaw) + ';' + ';result\n'
            break
        }
      }
      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-' + (new Date().toISOString().split('T')[0]) + '.csv')
      document.body.appendChild(link)
      link.click()
    }
  },
  watch: {
    activeTab () {

    }
  }
}
</script>

<style lang="scss">
.component-performance-report-table{
  .reports-performance-table {
    thead {
      display: none;
    }
    tr.result-data {
      border-top: 1px solid #000;
      border-bottom: 2px solid #000;
      font-weight: 900;
    }
    tr.subtotal-data, tr.subtotal-data-category-group {
      border-top: 1px solid #ACAEBA;
      border-bottom: 2px solid #ACAEBA;
      font-weight: 700;
    }

    tr.group-heading{
      td {
        border-top: none;
        padding-top:10px;
        padding-bottom: 0;
      }
      td.row-title{
        font-size:110%
      }
    }

    tr.type-header, tr.overview-account-type-header-income, tr.overview-account-type-header-expense{
      td {
        border-top: none;
        padding-top:20px;
        padding-bottom:0;
        background: white;
      }
      td:first-child {
        font-weight: bold;
        font-size:110%;
      }
    }

    tr.overview-account-type-header-income, tr.overview-account-type-header-expense{
      td:first-child{
        font-size: 125%;
      }
      td{
        border-bottom: 1px dashed black;
      }
    }

    tr.overview-account-type-header-expense td{
      padding-top:45px;
    }

    tr.group-heading:first-child{
      td{padding-top:0;}
    }

    tr.account-data .row-title {
      font-weight: 400;
    }
  }
}
</style>
