<template>
  <b-dropdown right
    :text="$t('common.toggle_columns')"
    variant='outline-secondary'
    class="float-right text-regular mr-3 toggleColumnWrapper"
    size="sm"
  >
    <b-dropdown-item @click="toggleColumn('current')" v-bind:class="{ active: this.columnState.current }">{{$t(translationPath + 'column_current')}}</b-dropdown-item>
    <b-dropdown-item @click="toggleColumn('budget')" v-bind:class="{ active: this.columnState.budget }">{{$t(translationPath + 'column_budget')}}</b-dropdown-item>
    <b-dropdown-item @click="toggleColumn('previous')" v-bind:class="{ active: this.columnState.previous }">{{$t(translationPath + 'column_previous')}}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
/**
 * Shows dropdown list with column names. Each column can be selected by user.
 * On select/unselect column, emits state (which columns are selected and which are not)
 * to parent component.
 */
export default {
  name: 'ToggleColumnsDropdown',
  props: {
    initialState: {
      type: Object
    }
  },
  data () {
    return {
      translationPath: 'reports.performance.performance_report.',
      columnState: {
        current: false,
        budget: false,
        previous: false
      }
    }
  },
  methods: {
    toggleColumn (sColumn) {
      this.columnState[sColumn] = !this.columnState[sColumn]
      this.$emit('change', this.columnState)
    }
  },
  created () {
    if (typeof this.initialState === 'object') {
      for (const colName in this.initialState) {
        if (Object.prototype.hasOwnProperty.call(this.columnState, colName)) {
          this.columnState[colName] = Boolean(this.initialState[colName])
        }
      }
    }
    this.$emit('change', this.columnState)
  }
}
</script>
