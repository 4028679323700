<template>
  <b-container fluid class="reports-performance-result-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'PerformanceDetailedChart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: ['data', 'periodColor', 'options'],
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance_report.'
    }
  },
  methods: {
    fillChart () {
      if (!this.data.length) {
        return false
      }

      const self = this

      const data = this.data.filter((item) => item.belongsTo === 'account-data' ? item : null)
      data.sort((a, b) => {
        return a.currentRaw >= b.currentRaw ? -1 : 1
      })

      const labels = []
      const dataPeriod = []
      const dataBudget = []
      const dataPrevious = []
      var tooltipLabels = [
        this.$t(this.translationPath + 'chart.period'),
        this.$t(this.translationPath + 'chart.budget'),
        this.$t(this.translationPath + 'chart.prev_period')
      ]

      let iCounter = 0
      let iAggregateBeyondXAccounts = 16
      if (this.options.budget) { iAggregateBeyondXAccounts -= 3 }
      if (this.options.previous) { iAggregateBeyondXAccounts -= 3 }
      if (iAggregateBeyondXAccounts < 12) { iAggregateBeyondXAccounts = 12 }

      const oAggregated = { label: this.$t('reports._common.aggregated'), period: 0, budget: 0, prev_period: 0, use_in_chart: false }

      data.map((item) => {
        if (item.currentRaw !== 0 || item.budgetRaw !== 0 || item.previousRaw !== 0) {
          if (iCounter++ <= iAggregateBeyondXAccounts) { // Aggregate when more than X accounts
            labels.push(self.splitForChartLabel(item.title))
            dataPeriod.push(parseFloat(item.currentRaw))
            dataBudget.push(parseFloat(item.budgetRaw))
            dataPrevious.push(parseFloat(item.previousRaw))
          } else {
            oAggregated.period += item.currentRaw
            oAggregated.budget += item.budgetRaw
            oAggregated.prev_period += item.previousRaw
            oAggregated.use_in_chart = true
          }
        }
      })

      if (oAggregated.use_in_chart > 0) {
        labels.push(this.splitForChartLabel(oAggregated.label))
        dataPeriod.push(parseFloat(oAggregated.period))
        dataBudget.push(parseFloat(oAggregated.budget))
        dataPrevious.push(parseFloat(oAggregated.use_in_chart))
      }

      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        const value = Math.round(tooltipItem.value)
        let subLabel = ''

        switch (tooltipItem.datasetIndex) {
          case 0:
            subLabel = tooltipLabels[0]
            break
          case 1:
            subLabel = tooltipLabels[1]
            break
          case 2:
            subLabel = tooltipLabels[2]
            break
        }

        return subLabel + ': ' + Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      const aDatasets = []

      if (this.options.current) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'period'),
          data: dataPeriod,
          backgroundColor: this.periodColor
        })
      }

      if (this.options.budget) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'budget'),
          data: dataBudget,
          backgroundColor: '#cbc6b5'
        })
      }

      if (this.options.previous) {
        aDatasets.push({
          label: this.$t(this.translationPath + 'period_prev'),
          data: dataPrevious,
          backgroundColor: '#9a9bae'
        })
      }

      this.chartOptions.legend.display = aDatasets.length > 1

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
