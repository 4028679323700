<template>
  <b-container fluid class="reports-performance-result-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'result-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'

export default {
  name: 'PerformanceResultChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes],
  props: ['data', 'currencySymbol', 'options'],
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance_report.'
    }
  },
  methods: {
    fillChart () {
      if (!this.data.length) {
        return false
      }

      const tooltipLabels = []

      const labels = [
        this.$t('common.incomes'),
        this.$t('common.expenses'),
        this.$t('common.result')
      ]
      const data = {}
      for (const item in this.data) {
        data[this.data[item].title] = this.data[item]
      }

      this.chartOptions.legend.display = false
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        let subLabel = ''

        switch (tooltipItem.datasetIndex) {
          case 0:
            switch (tooltipItem.index) {
              case 0:
                subLabel = tooltipLabels[0]
                break
              case 1:
                subLabel = tooltipLabels[1]
                break
              case 2:
                subLabel = tooltipLabels[2]
                break
            }
            break
          case 1:
            switch (tooltipItem.index) {
              case 0:
                subLabel = tooltipLabels[3]
                break
              case 1:
                subLabel = tooltipLabels[4]
                break
              case 2:
                subLabel = tooltipLabels[5]
                break
            }
            break
          case 2:
            switch (tooltipItem.index) {
              case 0:
                subLabel = tooltipLabels[6]
                break
              case 1:
                subLabel = tooltipLabels[7]
                break
              case 2:
                subLabel = tooltipLabels[8]
                break
            }
            break
        }
        const value = Math.round(tooltipItem.value)
        return subLabel + ': ' + Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' ' + currencySymbol
      }

      const aDatasets = []

      // If show current
      if (this.options.current) {
        aDatasets.push({
          label: ['test2', 'test3', 'test4'],
          data: [data.income.currentRaw, data.expense.currentRaw, data.difference.currentRaw],
          type: 'bar',
          backgroundColor: [this.getGreen(), this.getRed(), this.getPurple()]
        })
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.income_period'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.expense_period'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.result_period'))
      }

      // If show budget
      if (this.options.budget) {
        aDatasets.push({
          data: [data.income.budgetRaw, data.expense.budgetRaw, data.difference.budgetRaw],
          type: 'bar',
          backgroundColor: ['#cbc6b5', '#cbc6b5', '#cbc6b5']
        })
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.income_budget'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.expense_budget'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.result_budget'))
      }

      // If show previous period
      if (this.options.previous) {
        aDatasets.push({
          data: [data.income.previousRaw, data.expense.previousRaw, data.difference.previousRaw],
          type: 'bar',
          backgroundColor: ['#9a9bae', '#9a9bae', '#9a9bae']
        })
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.income_prev'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.expenses_prev'))
        tooltipLabels.push(this.$t(this.translationPath + 'chart.total.result_prev'))
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    data () {
      this.fillChart()
    },
    options: {
      deep: true,
      handler () {
        this.fillChart()
      }
    }
  },
  created () {
    this.fillChart()
  }
}
</script>
