<template>
  <div class="component-performance-report-performance">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'report_title') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="bIsPeriodSelectorShown"
                ref="PeriodSelector"
                :default-period-type="'rolling-3-months'"
                :show-comparison-period="true"
                :show-last-months="true"
                :default-start-date="period.current.start ? moment(period.current.start).format('YYYY-MM-DD') : null"
                :default-end-date="period.current.end ? moment(period.current.end).format('YYYY-MM-DD') : null"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <reports-performance-submenu ref="Tabmenu" v-show="true" class="overview" :active="activeTab" @tab-switched="onTabSwitched"></reports-performance-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper">
        <div class="pb-5" id="adjustableChartHeightWrapper" :style="'width:100%;height:'+(getAdjustableChartHeightWrapper(activeTab, sIncomeChartToDisplay, sExpenseChartToDisplay))+'px;'" v-if="activeTab === 'overview' || activeTab === 'income' || activeTab === 'expense'">
          <performance-result-chart v-if="activeTab === 'overview' && sOverviewChartToDisplay === 'overview-total-bar-chart'" :data="getTabResultData" :currencySymbol="currentCOA.currency" :options="toggleColumnsOptions"/>
          <performance-result-stacked-chart v-if="activeTab === 'overview' && sOverviewChartToDisplay === 'overview-aggregated-bar-chart'" :data="aggregatedTotalTableData" typeOfChart="aggregated" :api-data="apiData" :currencySymbol="currentCOA.currency" :options="toggleColumnsOptions" />
          <performance-result-stacked-chart v-if="activeTab === 'overview' && sOverviewChartToDisplay === 'overview-detailed-bar-chart'" :data="aggregatedTotalTableDetailedData" typeOfChart="detailed" :api-data="apiData" :currencySymbol="currentCOA.currency" :options="toggleColumnsOptions" />

          <performance-aggregated-bar-chart v-if="activeTab === 'income' && sIncomeChartToDisplay === 'income-aggregated-bar-chart'" :data="aggregatedIncomeTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(140, 204, 173, 0.8)'" :options="toggleColumnsOptions" />
          <performance-aggregated-pie-chart v-if="activeTab === 'income' && sIncomeChartToDisplay === 'income-aggregated-pie-chart'" :data="aggregatedIncomeTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(140, 204, 173, 0.8)'" :options="toggleColumnsOptions"/>

          <performance-aggregated-bar-chart v-if="activeTab === 'expense' && sExpenseChartToDisplay === 'expense-aggregated-bar-chart'" :data="aggregatedExpenseTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(225, 165, 160, 0.8)'" :options="toggleColumnsOptions"/>
          <performance-aggregated-pie-chart v-if="activeTab === 'expense' && sExpenseChartToDisplay === 'expense-aggregated-pie-chart'" :data="aggregatedExpenseTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(225, 165, 160, 0.8)'" :options="toggleColumnsOptions"/>

          <performance-detailed-bar-chart v-if="activeTab === 'income' && sIncomeChartToDisplay === 'income-detailed-bar-chart'" :data="detailedIncomeTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(140, 204, 173, 0.8)'" :options="toggleColumnsOptions"/>
          <!--<performance-detailed-pie-chart v-if="activeTab === 'income' && sIncomeChartToDisplay === 'income-detailed-pie-chart'" pieChartSize='full' :data="detailedIncomeTableData" :currencySymbol="currentCOA.currency" :periodColor="'#8cccad'" :options="toggleColumnsOptions"/>-->
          <performance-detailed-pie-chart v-if="activeTab === 'income' && sIncomeChartToDisplay === 'income-detailed-half-pie-chart'" pieChartSize='half' :data="detailedIncomeTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(140, 204, 173, 0.8)'" :options="toggleColumnsOptions"/>

          <performance-detailed-bar-chart v-if="activeTab === 'expense' && sExpenseChartToDisplay === 'expense-detailed-bar-chart'" :data="detailedExpenseTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(225, 165, 160, 0.8)'" :options="toggleColumnsOptions"/>
          <!--<performance-detailed-pie-chart v-if="activeTab === 'expense' && sExpenseChartToDisplay === 'expense-detailed-pie-chart'" pieChartSize='full' :data="detailedExpenseTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(225, 165, 160, 0.8)'" :options="toggleColumnsOptions"/>-->
          <performance-detailed-pie-chart v-if="activeTab === 'expense' && sExpenseChartToDisplay === 'expense-detailed-half-pie-chart'" pieChartSize='half' :data="detailedExpenseTableData" :currencySymbol="currentCOA.currency" :periodColor="'rgba(140, 204, 173, 0.8)'" :options="toggleColumnsOptions"/>
        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0">

      <performance-table
        v-if="activeTab === 'overview' && (sOverviewChartToDisplay==='overview-aggregated-bar-chart' || sOverviewChartToDisplay==='overview-total-bar-chart')"
        :activeTab="activeTab"
        :data="aggregatedTotalTableData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :title="$t(translationPath + 'table_result_title').toString()"
        :description="$t(translationPath + 'table_result_description').toString()"
        :period="period"
        :tableClass="'table-aggregated'"
        :options="toggleColumnsOptions"
        :activeOverviewChart="sOverviewChartToDisplay"
        @chart-to-display-overview="(sEmittedChartId) => sOverviewChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
        @request-tab="onTabSwitched"
      ></performance-table>

      <performance-table
        v-if="activeTab === 'overview' && sOverviewChartToDisplay==='overview-detailed-bar-chart'"
        :activeTab="activeTab"
        :data="aggregatedTotalTableDetailedData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :title="$t(translationPath + 'table_result_title').toString()"
        :description="$t(translationPath + 'table_result_description').toString()"
        :period="period"
        :tableClass="'table-aggregated'"
        :options="toggleColumnsOptions"
        :activeOverviewChart="sOverviewChartToDisplay"
        @chart-to-display-overview="(sEmittedChartId) => sOverviewChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
        @request-tab="onTabSwitched"
      ></performance-table>

      <performance-table
        v-if="activeTab === 'income' && (sIncomeChartToDisplay === 'income-aggregated-bar-chart' || sIncomeChartToDisplay === 'income-aggregated-pie-chart')"
        :activeTab="activeTab"
        :data="aggregatedIncomeTableData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :title="$t(translationPath + 'table_income_aggregated_title').toString()"
        :description="$t(translationPath + 'table_income_aggregated_description').toString()"
        :period="period"
        :tableClass="'table-aggregated'"
        :activeIncomeChart="sIncomeChartToDisplay"
        :options="toggleColumnsOptions"
        @chart-to-display-income="(sEmittedChartId) => sIncomeChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
      ></performance-table>

      <performance-table
        v-if="activeTab === 'expense' && (sExpenseChartToDisplay === 'expense-aggregated-bar-chart' || sExpenseChartToDisplay === 'expense-aggregated-pie-chart')"
        :activeTab="activeTab"
        :data="aggregatedExpenseTableData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :title="$t(translationPath + 'table_expense_aggregated_title').toString()"
        :description="$t(translationPath + 'table_expense_aggregated_description').toString()"
        :period="period"
        :tableClass="'table-aggregated'"
        :activeExpenseChart="sExpenseChartToDisplay"
        :options="toggleColumnsOptions"
        @chart-to-display-expense="(sEmittedChartId) => sExpenseChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
      ></performance-table>

      <performance-table
        v-if="activeTab === 'income' && (sIncomeChartToDisplay === 'income-detailed-bar-chart' || sIncomeChartToDisplay === 'income-detailed-pie-chart' || sIncomeChartToDisplay === 'income-detailed-half-pie-chart')"
        :activeTab="activeTab"
        :data="detailedIncomeTableData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :tableClass="'table-detailed'"
        :title="$t(translationPath + 'table_income_detailed_title').toString()"
        :description="$t(translationPath + 'table_income_detailed_description').toString()"
        :period="period"
        :options="toggleColumnsOptions"
        :activeIncomeChart="sIncomeChartToDisplay"
        @chart-to-display-income="(sEmittedChartId) => sIncomeChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
      ></performance-table>

      <performance-table
        v-if="activeTab === 'expense' && (sExpenseChartToDisplay === 'expense-detailed-bar-chart' || sExpenseChartToDisplay === 'expense-detailed-pie-chart' || sExpenseChartToDisplay === 'expense-detailed-half-pie-chart')"
        :activeTab="activeTab"
        :data="detailedExpenseTableData"
        :busyState="!isLoaded"
        :currentPeriodLabel="currentPeriodLabel"
        :previousPeriodLabel="previousPeriodLabel"
        :tableClass="'table-detailed'"
        :title="$t(translationPath + 'table_expense_detailed_title').toString()"
        :description="$t(translationPath + 'table_expense_detailed_description').toString()"
        :period="period"
        :options="toggleColumnsOptions"
        :activeExpenseChart="sExpenseChartToDisplay"
        @chart-to-display-expense="(sEmittedChartId) => sExpenseChartToDisplay = sEmittedChartId"
        @toggle-columns="handleEmittedColumnToggle"
      ></performance-table>

      <!-- REPORT FILTER -->
      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import titleMixins from '@/mixins/title'
import ReportsPerformanceSubmenu from './inc/ReportsPerformanceSubmenu.vue'
import PeriodSelector from '@/components/common/PeriodSelector'
import PerformanceTable from './tables/PerformanceTable'
import PerformanceResultChart from './charts/PerformanceResultChart'
import PerformanceAggregatedBarChart from './charts/PerformanceAggregatedBarChart'
import PerformanceAggregatedPieChart from './charts/PerformanceAggregatedPieChart'
import PerformanceDetailedBarChart from './charts/PerformanceDetailedBarChart'
import PerformanceDetailedPieChart from './charts/PerformanceDetailedPieChart'
import ReportFilter from '@/components/common/ReportFilter.vue'
import PerformanceResultStackedChart from './charts/PerformanceResultStackedChart'
import moment from 'moment'

export default {
  name: 'Performance',
  mixins: [titleMixins],
  components: {
    PeriodSelector,
    ReportsPerformanceSubmenu,
    PerformanceTable,
    PerformanceResultChart,
    PerformanceAggregatedBarChart,
    PerformanceAggregatedPieChart,
    PerformanceDetailedBarChart,
    PerformanceDetailedPieChart,
    PerformanceResultStackedChart,
    ReportFilter
  },
  filters: {
    formatAmount
  },
  data () {
    return {
      activeTab: 'overview',
      apiData: null,
      isLoaded: false,
      bIsPeriodSelectorShown: false,
      items: {
        income: null,
        expense: null,
        result: null
      },
      filter: {
        income: null,
        expense: null
      },
      period: {
        current: {
          start: null,
          end: null
        },
        previous: {
          start: null,
          end: null
        },
        formatted: {
          current_start_date: null,
          current_end_date: null
        }
      },
      toggleColumnsOptions: {
        current: true,
        budget: false,
        previous: false
      },
      sIncomeChartToDisplay: 'income-detailed-bar-chart',
      sExpenseChartToDisplay: 'expense-detailed-bar-chart',
      sOverviewChartToDisplay: 'overview-total-bar-chart',
      translationPath: 'reports.performance.performance_report.',
      reportFilterData: null,
      bIsFilterApplied: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    local () {
      return this.currentUser && Object.prototype.hasOwnProperty.call(this.currentUser, 'default_lang') && this.currentUser.default_lang ? this.currentUser.default_lang : 'en'
    },
    breadcrumbItems () {
      return [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('reports._common.performance_reports'), to: '/reports/performance' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'report_title')
    },
    currentPeriodLabel () {
      return this.formattedDate(this.period.current.start, 2) + ' - ' + this.formattedDate(this.period.current.end, 2)
    },
    previousPeriodLabel () {
      return this.formattedDate(this.period.previous.start, 2) + ' - ' + this.formattedDate(this.period.previous.end, 2)
    },
    getTabResultData () {
      if (this.apiData && Object.prototype.hasOwnProperty.call(this.apiData, 'totals')) {
        const data = [{
          title: '',
          belongsTo: 'group-heading',
          currentRaw: 0
        }]
        const slice = {
          income: this.apiData.totals.income,
          expense: this.apiData.totals.expense,
          difference: this.apiData.totals.result
        }

        for (const i in slice) {
          data.push({
            title: i,
            current: this.formatAmount(slice[i].current_period),
            currentRaw: slice[i].current_period,
            budget: this.formatAmount(slice[i].budget),
            budgetRaw: slice[i].budget,
            previous: this.formatAmount(slice[i].previous_period),
            previousRaw: slice[i].previous_period,
            belongsTo: i === 'difference' ? 'result-data' : 'type-data'
          })
        }

        return data
      }
      return []
    },
    aggregatedIncomeTableData () {
      return this.createTableAggregatedGroupData('income')
    },
    aggregatedExpenseTableData () {
      return this.createTableAggregatedGroupData('expense')
    },
    detailedIncomeTableData () {
      return this.createTableDetailedCategoryData('income')
    },
    detailedExpenseTableData () {
      return this.createTableDetailedCategoryData('expense')
    },
    aggregatedTotalTableData () {
      return this.createTotalTableAggregatedGroupData()
    },
    aggregatedTotalTableDetailedData () {
      return this.createTotalTableDetailedData()
    }
  },
  methods: {
    handleSectionUpdate (value) {
      this.sections = value
    },
    getAdjustableChartHeightWrapper (sActiveTab, sIncomeChart, sExpenseChart) {
      let sChart = sIncomeChart

      if (sActiveTab === 'expense') {
        sChart = sExpenseChart
      }

      if (sActiveTab === 'overview') {
        sChart = 'empty'
      }

      switch (sChart) {
        case 'income-detailed-bar-chart':
        case 'income-detailed-pie-chart':
        case 'expense-detailed-bar-chart':
        case 'expense-detailed-pie-chart':
          return 500
        default:
          return 500
      }
    },
    handleEmittedColumnToggle (colState) {
      for (const colName in colState) {
        if (Object.prototype.hasOwnProperty.call(this.toggleColumnsOptions, colName)) {
          this.toggleColumnsOptions[colName] = colState[colName]
        }
      }
    },
    handleSettingsUpdate (value) {
      this.handleSectionUpdate(value)
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    createTableAggregatedGroupData (type) {
      if (this.apiData && Object.prototype.hasOwnProperty.call(this.apiData, 'totals')) {
        const data = [{
          title: '',
          belongsTo: 'group-heading'
        }]

        const aGroups = this.apiData[type]

        for (const iGroupIdx in aGroups) {
          data.push({
            id: aGroups[iGroupIdx].id,
            title: aGroups[iGroupIdx].title,
            account_type: aGroups[iGroupIdx].type,
            title_with_code: aGroups[iGroupIdx].title_with_code,
            current: this.formatAmount(aGroups[iGroupIdx].totals.current_period),
            currentRaw: aGroups[iGroupIdx].totals.current_period,
            budget: this.formatAmount(aGroups[iGroupIdx].totals.budget),
            budgetRaw: aGroups[iGroupIdx].totals.budget,
            previous: this.formatAmount(aGroups[iGroupIdx].totals.previous_period),
            previousRaw: aGroups[iGroupIdx].totals.previous_period,
            belongsTo: 'group-data'
          })
        }

        data.push({
          title: 'total',
          current: this.formatAmount(this.apiData.totals[type].current_period),
          budget: this.formatAmount(this.apiData.totals[type].budget),
          previous: this.formatAmount(this.apiData.totals[type].previous_period),
          belongsTo: 'result-data',
          type: type
        })

        return data
      }
      return []
    },
    createTableDetailedCategoryData (type) {
      if (this.apiData && Object.prototype.hasOwnProperty.call(this.apiData, 'totals')) {
        const data = [{
          title: '',
          belongsTo: 'empty-row'
        }]

        const aGroups = this.apiData[type]

        for (const iGroupIdx in aGroups) {
          data.push({
            id: aGroups[iGroupIdx].id,
            title: aGroups[iGroupIdx].title,
            title_with_code: aGroups[iGroupIdx].title_with_code,
            account_type: aGroups[iGroupIdx].type,
            belongsTo: 'group-heading'
          })

          for (const iAcctIdx in aGroups[iGroupIdx].accounts) {
            const oAccount = aGroups[iGroupIdx].accounts[iAcctIdx]
            data.push({
              id: oAccount.id,
              title: oAccount.title,
              title_with_code: oAccount.title_with_code,
              account_type: oAccount.type,
              group_name: aGroups[iGroupIdx].title,
              current: this.formatAmount(oAccount.totals.current_period),
              currentRaw: parseFloat(oAccount.totals.current_period),
              budget: this.formatAmount(oAccount.totals.budget),
              budgetRaw: oAccount.totals.budget,
              previous: this.formatAmount(oAccount.totals.previous_period),
              previousRaw: oAccount.totals.previous_period,
              belongsTo: 'account-data'
            })
          }

          data.push({
            title: 'subtotal',
            current: this.formatAmount(aGroups[iGroupIdx].totals.current_period),
            budget: this.formatAmount(aGroups[iGroupIdx].totals.budget),
            previous: this.formatAmount(aGroups[iGroupIdx].totals.previous_period),
            belongsTo: 'subtotal-data',
            groupId: aGroups[iGroupIdx].id,
            groupName: aGroups[iGroupIdx].title
          })

          data.push({
            title: '',
            belongsTo: 'empty-row'
          })
        }

        data.push({
          title: 'total',
          current: this.formatAmount(this.apiData.totals[type].current_period),
          budget: this.formatAmount(this.apiData.totals[type].budget),
          previous: this.formatAmount(this.apiData.totals[type].previous_period),
          currentRaw: this.apiData.totals.result.current_period,
          budgetRaw: this.apiData.totals.result.budget,
          previousRaw: this.apiData.totals.result.previous_period,
          belongsTo: 'result-data',
          type: type
        })

        return data
      }
      return []
    },
    createTotalTableAggregatedGroupData () {
      if (!this.isLoaded) { return [] }

      let rows = []
      rows = this.createTableAggregatedGroupData('income')
      rows[0].title = this.$t(this.translationPath + 'income_groups')
      rows[0].title_with_code = rows[0].title
      rows[0].belongsTo = 'type-header'

      const aExpenseRows = this.createTableAggregatedGroupData('expense')
      aExpenseRows[0].title = this.$t(this.translationPath + 'expense_groups')
      aExpenseRows[0].title_with_code = aExpenseRows[0].title
      aExpenseRows[0].belongsTo = 'type-header'

      rows = rows.concat(aExpenseRows)

      rows.push({
        title: '',
        belongsTo: 'empty-row'
      })

      // Fix parts sums
      for (const idx in rows) {
        if (rows[idx].belongsTo === 'result-data') {
          rows[idx].title = 'subtotal-data-' + rows[idx].type
          rows[idx].title_with_code = rows[idx].title
          rows[idx].belongsTo = 'subtotal-data'
        }
      }

      rows.push({
        title: 'result',
        current: this.formatAmount(this.apiData.totals.result.current_period),
        budget: this.formatAmount(this.apiData.totals.result.budget),
        previous: this.formatAmount(this.apiData.totals.result.previous_period),
        currentRaw: this.apiData.totals.result.current_period,
        budgetRaw: this.apiData.totals.result.budget,
        previousRaw: this.apiData.totals.result.previous_period,
        belongsTo: 'result-data',
        type: 'total'
      })

      return rows
    },
    createTotalTableDetailedData () {
      if (!this.isLoaded) { return [] }

      let rows = []
      rows = this.createTableDetailedCategoryData('income')
      rows[0].title = this.$t(this.translationPath + 'income_categories')
      rows[0].title_with_code = rows[0].title
      rows[0].belongsTo = 'overview-account-type-header-income'

      const aExpenseRows = this.createTableDetailedCategoryData('expense')
      aExpenseRows[0].title = this.$t(this.translationPath + 'expense_categories')
      aExpenseRows[0].title_with_code = aExpenseRows[0].title
      aExpenseRows[0].belongsTo = 'overview-account-type-header-expense'

      rows = rows.concat(aExpenseRows)

      // Fix parts sums
      for (const idx in rows) {
        if (rows[idx].belongsTo === 'subtotal-data') {
          rows[idx].belongsTo = 'subtotal-data-category-group'
          rows[idx].title = this.$t('common.subtotal') + ' (' + rows[idx].groupName.toLocaleLowerCase() + ')'
          rows[idx].title_with_code = this.$t('common.subtotal') + ' (' + rows[idx].groupName.toLocaleLowerCase() + ')'
        }
        if (rows[idx].belongsTo === 'result-data') {
          rows[idx].title = 'subtotal-data-' + rows[idx].type
          rows[idx].title_with_code = 'subtotal-data-' + rows[idx].type
          rows[idx].belongsTo = 'subtotal-data'
        }
      }

      rows.push({
        title: '',
        belongsTo: 'empty-row'
      })

      rows.push({
        title: 'result',
        current: this.formatAmount(this.apiData.totals.result.current_period),
        budget: this.formatAmount(this.apiData.totals.result.budget),
        previous: this.formatAmount(this.apiData.totals.result.previous_period),
        currentRaw: this.apiData.totals.result.current_period,
        budgetRaw: this.apiData.totals.result.budget,
        previousRaw: this.apiData.totals.result.previous_period,
        belongsTo: 'result-data',
        type: 'total'
      })

      return rows
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab }, query: this.$route.query })
      }
    },
    onPeriodChange (data) {
      this.period.current.start = data.oDateStart
      this.period.current.end = data.oDateEnd
      this.period.previous.start = data.oComparisonDateStart
      this.period.previous.end = data.oComparisonDateEnd

      this.period.formatted.current_start_date = this.formattedDate(data.oDateStart)
      this.period.formatted.current_end_date = this.formattedDate(data.oDateEnd)

      this.loadData()
    },
    formattedDate (date, mode) {
      if (!date) {
        return ''
      }

      let month = (date.getMonth() + 1) + ''
      let day = date.getDate() + ''
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      let output = ''

      switch (mode) {
        case 2:
          output = (date.getFullYear() + '').substr(2) + month + day
          break
        default:
          output = date.getFullYear() + '-' + month + '-' + day
      }

      return output
    },
    formatAmount (amount) {
      const amountFormatted = formatAmount(
        amount,
        this.currentCOA.locale,
        this.currentCOA.currency,
        this.currentCOA.currency_iso,
        true,
        0
      )

      if (amountFormatted === false) {
        return amount
      }
      return amountFormatted
    },
    async loadData () {
      if (this.currentCOA.locale === null || !this.period.previous.start || !this.period.previous.end) {
        return false
      }

      this.isLoaded = false
      let ApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/performance?current_start_date=${this.formattedDate(this.period.current.start)}&current_end_date=${this.formattedDate(this.period.current.end)}&previous_start_date=${this.formattedDate(this.period.previous.start)}&previous_end_date=${this.formattedDate(this.period.previous.end)}&locale=${this.currentCOA.locale}`

      // Filter accounts
      const aIncludeAccounts = []
      if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectIncome')) {
        this.reportFilterData.oFormSelectIncome.map(a => { aIncludeAccounts.push(a) })
        this.reportFilterData.oFormSelectExpense.map(a => { aIncludeAccounts.push(a) })
        this.reportFilterData.oFormSelectAsset.map(a => { aIncludeAccounts.push(a) })
        this.reportFilterData.oFormSelectLiability.map(a => { aIncludeAccounts.push(a) })
      }
      if (aIncludeAccounts.length) {
        ApiEndpoint += '&filter_accounts=' + aIncludeAccounts.join(',')
      }

      // Filter persons
      if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectPerson')) {
        ApiEndpoint += '&filter_persons=' + this.reportFilterData.oFormSelectPerson.join(',')
      }

      await axios.get(ApiEndpoint)
        .then((response) => {
          this.apiData = response.data.data
          this.isLoaded = true
        })
        .catch((error) => {
          console.error(error)
          this.isLoaded = true
        })
      // const ApiEndpointpdf = `http://api.spirecta.test/export/performance-report?current_start_date=${this.formattedDate(this.period.current.start)}&current_end_date=${this.formattedDate(this.period.current.end)}&previous_start_date=${this.formattedDate(this.period.previous.start)}&previous_end_date=${this.formattedDate(this.period.previous.end)}&locale=${this.currentCOA.locale}`
    },
    changeTab (tab) {
      if (['overview', 'income-aggregated', 'income-detailed', 'expense-aggregated', 'expense-detailed', 'filter'].indexOf(this.$route.params.tab) !== -1) {
        this.$refs.Tabmenu.changeTab(tab)
        this.setPageTitle(this.titleMeta)
      }
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.oFormSelectAsset = []
        this.reportFilterData.oFormSelectLiability = []
        this.reportFilterData.oFormSelectPerson = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    moment (dt) {
      return moment(dt)
    }
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      this.changeTab(this.$route.params.tab)
    },
    /**
     * If query is changed - we modify dates in period selector.
     * PeriodSelector will emit change event, so new data will be loaded.
     */
    '$route.query' () {
      this.$refs.PeriodSelector.$setValues({
        sDateStart: moment(this.$route.query.start_date).format('YYYY-MM-DD'),
        sDateEnd: moment(this.$route.query.end_date).format('YYYY-MM-DD')
      })
    },
    /**
     * If period is changed we modify route query
     */
    period: {
      deep: true,
      immediate: false,
      handler () {
        const query = {}
        if (this.period.current.start) {
          query.start_date = moment(this.period.current.start).format('YYYY-MM-DD')
        }
        if (this.period.current.end) {
          query.end_date = moment(this.period.current.end).format('YYYY-MM-DD')
        }

        let bUpdateQuery = false
        const paramNames = ['start_date', 'end_date']
        for (const i in paramNames) {
          if (this.$route.query[paramNames[i]] !== query[paramNames[i]]) {
            bUpdateQuery = true
            break
          }
        }

        if (bUpdateQuery) {
          this.$router.push({ name: this.$route.name, query: query })
        }
      }
    }
  },
  mounted () {
    this.$root.$on('selection-change', (value) => {
      this.toggleColumnsOptions = Object.assign({}, this.toggleColumnsOptions, value)
    })
    if (this.$route.query.start_date) {
      this.period.current.start = new Date(this.$route.query.start_date)
    }
    if (this.$route.query.end_date) {
      this.period.current.end = new Date(this.$route.query.end_date)
    }
    this.bIsPeriodSelectorShown = true
    this.changeTab(this.$route.params.tab)
  }
}
</script>

<style lang="scss">
.component-performance-report-performance {
  width: 100%;

  .card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }
  .account_title {
    font-weight: normal;
  }
  .table-title {
    font-size: 24px;
    line-height: 32px;
    color: #434343;
  }
  .table-title-label {
    width: 24px;
    height: 24px;
    border: 3px solid #D8D8D8;
    border-radius: 20px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 10px;
  }
  .spirecta-simple-table {
    tbody {
      tr {
        &.b-table-top-row{
          @media print {
            display: none;
          }
        }
        td {
          a {
            @media print {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  .table-title-label-icon {
    position: relative;
    left: -27px;
    top: -2px;
    font-size: 18px;
    font-weight: 700;
  }
  .table-title-help {
    color: #0065ff;
  }
  .group_result_row {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    .row-title {
      font-style: italic;
      font-weight: 400;
    }
  }
  th:last-child, td:last-child {
    padding-right: 20px;
  }
  .performance-menu-container {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 100%;
    font-size: 23px;
    font-weight: 400;
    line-height: 32px;
    color: #acaeba;
  }
  .performance-report-table-wrapper {
    .card-body {
      padding-top: 0;
    }
  }
}
</style>
