<template>
  <div class="performance-menu-container print-hide">
    <b-row class="submenu-container dark justify-content-center" no-gutters>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="overview" :class="{selected: activeTab === 'overview'}"  @click="onTabClick">
          {{ $t(translationPath + 'total') }}
        </b-link> <span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="income" :class="{selected: activeTab === 'income'}"  @click="onTabClick">
          {{ $t('common.incomes') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="expense" :class="{selected: activeTab === 'expense'}"  @click="onTabClick">
          {{ $t('common.expenses') }}
        </b-link><span class="main-gradient-header-tab-menu-divider mx-3">|</span>
      </b-col>
      <b-col md="auto">
        <b-link class="main-gradient-header-tab-menu-tab-item" tab="filter" :class="{selected: activeTab === 'filter'}"  @click="onTabClick">
          {{ $t('common.filter') }}
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: 'overview'
    }
  },
  name: 'ReportsPerformanceSubmenu',
  data () {
    return {
      activeTab: null,
      translationPath: 'reports.performance.performance_report.menu.'
    }
  },
  created () {
    this.activeTab = this.active
  },
  methods: {
    onTabClick (event) {
      this.activeTab = event.target.attributes.tab.value
      this.$emit('tab-switched', event.target.attributes.tab.value)
    },
    changeTab (tab) {
      this.activeTab = tab
      this.$emit('tab-switched', tab)
    }
  },
  watch: {
    active: {
      deep: true,
      handler () {
        this.activeTab = this.active
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/submenu.scss';
  .site-divider{
    margin-top:10px;
    margin-bottom:20px;
  }
  .performance-menu-container {
    margin-top: 0px;
  }
  .submenu-container {
    &.dark {
      .col-md-auto {
        padding-right: 0;
      }
    }
  }
</style>
